import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg53.png").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
            <h2 className="title">Bienvenidos a Koliakos Club</h2>
            <h3>Evolución sin TACC</h3>
            <div className="text-center">
              <Button
                className="btn-round"
                color="info"
                href="https://docs.google.com/forms/d/e/1FAIpQLSebRP_bpokQ5SFSX39h9lFH56XCWxJZeLHl3Ald8Dtmz7FXQA/viewform"
                target="_blank"
                rel="noreferrer"
              >
                Quiero publicar un artículo
              </Button>
              <Button
                className="btn-round"
                color="info"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe0nSdCZIKStaEYkMlzbR_zrzYbTxx1s8LN98Q3bUcOcpG3TQ/viewform"
                target="_blank"
                rel="noreferrer"
              >
                Quiero publicar una receta
              </Button>
              <Button
                className="btn-round"
                color="info"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfbZKQNSD5E4gwOImyiD6VVt0D8_JXAsjCP7R48D3dq7KVqpQ/viewform"
                target="_blank"
                rel="noreferrer"
              >
                Quiero publicar mi comercio
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
