/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterDefault() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                  Kolaikos Club
              </li>
              <li>
                  <a href="#">Términos y Condiciones</a>
              </li>
              <li>
                  <a href="#">Políticas de Privacidad</a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Todos los derechos reservados.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterDefault;
