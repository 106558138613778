import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function DropdownFixedNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="navbar-absolute navbar-transparent" expand="lg">
        <Container>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <Button
                className="btn-icon btn-round mr-1"
                color="info"
                href="https://www.facebook.com/koliakosclubok"
                target="_blank"
                rel="noreferrer"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
              </NavItem>
              <NavItem>
                <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.instagram.com/koliakosclubok/"
                target="_blank"
                rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </Button>
              </NavItem>
              <NavItem>
                <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.linkedin.com/company/koliakosok"
                target="_blank"
                rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default DropdownFixedNavbar;
