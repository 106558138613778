import React from "react";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterDefault from "components/Footers/FooterDefault.js";

function LandingPage() {
  const [pills, setPills] = React.useState("1");
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [contactFocus, setContactFocus] = React.useState(false);
  const [addressFocus, setAddressFocus] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const saveData = async (event) => {
    event.preventDefault();
    window.grecaptcha.ready(async function() {
      window.grecaptcha.execute('6Lf5i9UgAAAAAAilNJ6ifMdYCn4WbeL_gW8qlbW-', {action: 'submit'}).then(async function(token) {

        const { GoogleSpreadsheet } = require('google-spreadsheet');
        const doc = new GoogleSpreadsheet('1Lxw5PCDI8X5lpZxLd4WWjC7TFKcRSKBEUxqSG699xog');
    
        await doc.useServiceAccountAuth({
          client_email: 'koliakosclub@m1mapsproyecto.iam.gserviceaccount.com',
          private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQClAhZnqa215L5Y\nQ2ynJuf2nDBmUUgDBBoc0EUAC7pYe23r8Pp4aWxYl0+M4bDiAPz8uLDaTirr0Azd\nfQcM1r4XM6gvR9trNLoT6K83knqWpr/RTTyZaXXJJvyHyubQZje3ZxNw/XukKsrW\nrWBY8kI3kHiUmmgZjK8cHbtUpaFxKQ/SlDZST5tdYASAf8mlCb4SMoYzPdMt4y0y\nZgttxAhLV/firbnIeB4W6DPsI6ZOib6fsrb7+1Nv61MoPerMLJyKT3rItbUZYebi\nq4c6Bz7Bowl3Rf1kNbLHo2bki2qNBInzeoSq4LCO9qEFvW0o9mN5DO/ksJ3X3Rux\nZ6q3BYG3AgMBAAECggEAL473eC9OkvN37SaZxV+QUeLYjFT3BW+EOzPH/2hh8Ci7\nGNrexh/Ui4Ao/Hj8K1ePkyOETZdkBY10d84WXxvxs+w+mEoEstSCoURaZBEC5jLv\nbFrvkWP0/+INvPG9LumXO3QpBVL59OokhlV4RIqmjS9FoccTI/IJcngbSx1l96Ag\na8OCSEea2d0MUUNNxTU92rSM8bk7zwkrXjkBhs0tsMNDVHMm7lS4ycT1VItK9q9T\nZewUDxV87Gx2x2nu+fWhBiVskPpX90NrrCqk6a5/KsUby7iuMFgp+P0gsVSDZNTt\nrNetHCg5dvvKtWqNXlPSlXFKRzqLjVezdA8nRCbo0QKBgQDatj6wps4GKiDrJ5GR\nMgNe3d9hXwrFkZBQFHWPo61z1oFQ0vDmUS+eqFzj2u5ocvKkbS8wgn3j/c/6XgIN\npo1iQ3OXfPiEx/00ACKgPcZCRn0V5/MCVynBetJNCOGfIz74siTyIdlD9Hp8YffY\nh+1NF5RCfihYWvf43LQmbpG2zwKBgQDBI+y0JURBHKREx7QGKUiamAAmoLI2YzZU\nWYa4bXHg/X4cSLiJxcJLtRqUJmVtwP7gHrTik963uW4m7j9oz4aoElJs0ykR/fFy\nM+NoP4ZTImn3Lkd5uq5ejDBJfXtMZ5Xave88QpAqB2ta9O+0ruXBnhhVdme09jCS\n9PnWjuzAmQKBgQCcotjS+yOiFpns0DHYotD3KD/LJJ46UL3iHET10mtvArkqgWg2\nCv7nyfx801bbRzevuuKJA2vV1PkWfi1KSXYIYfZvD/mMXw5gJ5ik2pka7hC3l57a\nlkI5ZxUmRq4PotZ+llcPcl5/5J+0ZRVz5iV1iOWNmXybisr9WFNNEvMY0QKBgQCF\nqD/BNtzyFkkTlAPgE6e73B6WnLRXcPcDDmVtsceSBLrGBgk2xpU74RuAlMWyW1HG\ndVmGRwBksj0pUcyt0peELhG+X67XVZ5GAuSHmxZw56rfNLaf5fGPWoJ8CQtPh4ur\nsa8iS4oKm9HOQxyzNCmpgKekVOlV0N0yhbdzpLrwWQKBgCs1LSxO3nvbcbzmzr5i\nkqDRXZBC8LSfYvALJRVt6d5mDbeuxzURqgNJ0o/17Ox6AxJI//tBX4dUgtxWMKgy\nnuDoV6XvTp33WYaxnUmwdsFRgzZ5LSFPgX5L3uB2vFxH3UDCCo5vET+fEQrWdZ1q\nWCjTaL3TDu67EQBRtyGPtfkj\n-----END PRIVATE KEY-----\n'.replace(/\\n/g, "\n"),
        });
        await doc.loadInfo();
    
        var model = {
          Type: pills === "1" ? "Publico" : "Comercio",
          Name: name || "",
          Email: email || "",
          Contact: contact || "",
          Address: address || "",
          Message: message || "",
          IP: "",
          Country: "",
          Region: "",
          State: "",
          ZipCode: "",
        }
    
        var response = await axios.get("https://geolocation-db.com/json/", {
          params: {
            "Access-Control-Allow-Origin": "*",
          }
        });
        console.log(response.data);
        model.IP = response.data.IPv4;
        model.Country = response.data.country_name;
        model.Region = response.data.city;
        model.State = response.data.state;
        model.ZipCode = "(" + response.data.latitude + "," + response.data.longitude + ")";
    
        const sheet = doc.sheetsByIndex[0];
        await sheet.addRow(model);
    
        window.location.reload();
    

      });
    });
  }


  return (
    <>
      <DropdownFixedNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title">¿Quiénes somos?</h1>
                <h5 className="description" style={{color: 'green'}}>
                  Koliakos Club nace como una plataforma para brindar información y crear una comunidad de usuarios que buscan aprender y compartir conocimientos acerca del consumo sin TACC.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-info"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg38.webp").default + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info"><strong>
                      "No empieces una dieta que terminará algún día. Comienza un estilo de vida que dure para siempre."	
                      </strong></p>
                  </div>
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg24.png").default + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg39.jfif").default + ")",
                    }}
                  ></div>
                  <h3>
                    Nos presentamos
                  </h3>
                  <p>Somos Koliakos Club, una plataforma de distribución de información y conocimiento acerca de un mundo sin TACC.</p>
                  <p>En el camino de una vida sin gluten, y viviendo todos y cada uno de los obstáculos q se nos presentan;  emprendimos el desafío de mejorar, de brindar calidad en información, que sea viable y gratuita; solo para poner nuestro granito de arena y comprometernos a una vida saludable; te invitamos a conocernos, plataforma de excelencia en información y conocimiento acerca de un mundo sin TACC.</p>
                  <p>
                  La nuestra es una plataforma Web y Móvil, donde encontraras información, videos, artículos, recetas, comercios, y todo lo que necesitas saber para poder organizar tu día a día y estar mejor preparado para el futuro.
                  </p>
                  <p>
                  Tenes que planificar vacaciones, o una salida; con nuestro buscador de comercios vas a poder hacerlo sin preocuparte por saber dónde comprar o dónde ir a comer. 
                  </p>
                  <p>
                  Te invitamos a sumarte a Koliakos, verdadera evolucion sin TACC, un mundo que construimos entre todos.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div
          className="testimonials-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg19.jfif").default + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Nuestro equipo</h2>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={"https://media-exp1.licdn.com/dms/image/C4E03AQFygICLB9rjeQ/profile-displayphoto-shrink_400_400/0/1580732919758?e=1658966400&v=beta&t=3XXjVXcqPyzgSnsRFXQ1ckgOMPx_Vt8xoV7kGMThB7M"}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description" style={{color: 'green'}}><strong>
                      Al frente del área técnica y el área de ejecución, lidera al equipo que crea la magia para que esta plataforma tome vida
                      </strong></p>
                  </CardBody>
                  <div className="icon icon-info">
                  <a rel={"noreferrer"} href={"https://www.linkedin.com/in/hteijiz"} target={"_blank"}><i className="fa-brands fa-linkedin"></i></a>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Hugo Teijiz</CardTitle>
                    <p className="category">CEO/Head of Tech</p>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={"https://media-exp1.licdn.com/dms/image/C4D03AQFuCQhm6_MhiA/profile-displayphoto-shrink_400_400/0/1517514268712?e=1658966400&v=beta&t=FSCP69pipFjVVHc8ksilelS9fOh01Ab5i79Gn0ctTs0"}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description" style={{color: 'green'}}><strong>
                    Escuchando y analizando cada propuesta, es quien vela como agente de salud, para que todo esté en su lugar y desarrolles la mejor experiencia de usuario
                      </strong></p>
                  </CardBody>
                  <div className="icon icon-info">
                  <a rel={"noreferrer"} href={"https://www.linkedin.com/in/aregueira"} target={"_blank"}><i className="fa-brands fa-linkedin"></i></a>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Adriana Regueira</CardTitle>
                    <p className="category">Head of Product/Sales</p>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={"https://media-exp1.licdn.com/dms/image/C4D03AQEgxy-FuS9uug/profile-displayphoto-shrink_400_400/0/1642552457813?e=1658966400&v=beta&t=lOFLrycrcTHqp-b9FrXtVTitiKjmbTkIHyhHcqZsyig"}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description" style={{color: 'green'}}><strong>
                      Nuestro mago del marketing, crea las mejores campañas, para que nos conozcan en cada rincón del planeta
                      </strong></p>
                  </CardBody>
                  <div className="icon icon-info">
                  <a rel={"noreferrer"} href={"https://www.linkedin.com/in/regleroc/"} target={"_blank"}><i className="fa-brands fa-linkedin"></i></a>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Carlos Reglero</CardTitle>
                    <p className="category">Head of Marketing</p>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title">Sumate al Club</h2>
            <p className="description" style={{color: 'green'}}><strong>Tanto si quieres sumarte a la comunidad, como si tienes un comercio y lo quieres promocionar para que todos lo conozcan. Escribenos y te agregaremos para que cuando lancemos, seas de los primeros en participar.</strong></p>
                <Nav
                  className="nav-pills-info justify-content-center"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={pills === "1" ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("1");
                      }}
                      role="tablist"
                      href="#pablo"
                    >
                      Soy Persona 
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pills === "2" ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("2");
                      }}
                      role="tablist"
                      href="#pablo"
                    >
                      Soy Comercio
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <br />
            <Row style={{
                    display: pills === "1" ? "block" : "none"
            }}>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nombre Completo..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    onChange={(e) => setName(e.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    emailFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                    onChange={(e) => setMessage(e.target.value)}
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => saveData(e)}
                    size="lg"
                  >
                    Enviar Datos
                  </Button>
                </div>
              </Col>
            </Row>
            <Row style={{
                    display: pills === "2" ? "block" : "none"
            }}>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nombre del Comercio..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    onChange={(e) => setName(e.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    addressFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons business_bank"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Domicilio del Comercio..."
                    type="text"
                    onFocus={() => setAddressFocus(true)}
                    onBlur={() => setAddressFocus(false)}
                    onChange={(e) => setAddress(e.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    contactFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nombre de Contacto..."
                    type="text"
                    onFocus={() => setContactFocus(true)}
                    onBlur={() => setContactFocus(false)}
                    onChange={(e) => setContact(e.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    emailFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email de Contacto..."
                    type="text"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                    onChange={(e) => setMessage(e.target.value)}
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => saveData(e)}
                    size="lg"
                  >
                    Enviar Datos
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterDefault />
      </div>
    </>
  );
}

export default LandingPage;
